<!--
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2022-01-01 13:02:21
 * @LastEditors: 番茄
 * @LastEditTime: 2022-02-03 00:50:21
-->
<template>
  <div class="chapter-container" :class='bgColorFlag ? "white" : "yellow"'>
    <nut-skeleton width="250px" height="15px" title animated avatar :loading="showData" row="8">
      <div v-if="!dataGetError">
        <nut-popup position="left" :class='bgColorFlag ? "nutpop-white" : "nutpop-yellow"' v-model:visible="showChapterPop" :style="{ height: '100%', width: '80%' }">
          <div class="contents">
            <div class="boxs">
              <h2>{{ chapterTitle }}</h2>
              <div class="new-chapter">
                <div class="new-title">
                  连载至 {{ chapterData[chapterData.length - 1]?.chapterName }}
                </div>
                <div>
                  <span>正序</span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="chapter-list-box">
            <div class="position-chapter">
              <div class="box" :class="[nowChapterNum === index + 1 ? 'check-chapter' : '']" v-for="(item, index) in chapterData" :key="index">
                <span @click="getDetail(item.chapterUrl, index)">{{
									item.chapterName
								}}</span>
              </div>
            </div>
          </div>
        </nut-popup>
        <div class="detail">
          <nut-drag direction="y" :style="{ right: '0px', top: '10px' }">
            <nut-fixednav v-model:visible="menuVisible" :class='bgColorFlag ? "nutwhite" : "nutyellow"'>
              <template v-slot:list>
                <ul class="nut-fixednav__list">
                  <li @click="menuClick(item.id)" class="nut-fixednav__list-item" v-for="item in menuList" :key="item.id">
                    {{ item.text }}
                  </li>
                </ul>
              </template>
              <template v-slot:btn>
                <!-- <nut-icon name="retweet" color="#fff"> </nut-icon> -->
                <span class="text">{{
									menuVisible ? '关闭菜单' : '打开菜单'
								}}</span>
              </template>
            </nut-fixednav>
          </nut-drag>

          <div class="header">
            <span>{{ chapterDetail.chapterTitle }}</span>
            <!-- <div class="menu" @click="showPopup">
							<nut-icon name="horizontal-n"></nut-icon>
							<span>目录</span>
						</div> -->
            <span></span>
          </div>
          <div class="content" :style="{
							fontSize: wordSize + 'px',
						}">
            <div class="detail-content" v-html="chapterDetail.chapterContent"></div>

            <div class="page-wrap">
              <nut-button type="info" :class='bgColorFlag ? "btn-white" : "btn-yellow"' class="prev" :disabled="nowChapterNum === 1" :loading="prevPageLoading" @click="prev">上一章</nut-button>
              <nut-button type="info" :class='bgColorFlag ? "btn-white" : "btn-yellow"' class="next" :loading="nextPageLoading" :disabled="nowChapterNum === chapterData.length" @click="next">下一章</nut-button>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="error-nodata">
        <nut-icon name="refresh2" @click="refresh"></nut-icon>
        <p>数据请求失败，点击图标重新请求一次8</p>
      </div>
    </nut-skeleton>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRoute } from "vue-router";
import { getChapter, getChapterDetail } from "@/api/search";

export default {
  setup() {
    const route = useRoute();
    const bookItem = JSON.parse(route.params.bookItem);
    onMounted(() => {
      getChapterData();
    });

    const state = reactive({
      chapterPath: bookItem.bookPath,
      isFromHome: route.params.isFromHome,
      chapterData: [],
      chapterTitle: "",
      showData: false,
      dataGetError: false,
      showChapterPop: false,
      chapterDetail: {},
      nowChapterNum: 1,
      prevPageLoading: false,
      nextPageLoading: false,
      menuVisible: false,
      menuList: [
        { id: 1, text: "目录" },
        { id: 2, text: "字号+" },
        { id: 3, text: "字号-" },
        { id: 4, text: "换肤" },
      ],
      wordSize: localStorage.getItem("wordSize") ?? 20,
      bgColorFlag: localStorage.getItem("bgColorFlag") ? true : false,
    });

    const menuClick = (item) => {
      switch (item) {
        case 1:
          state.menuVisible = false;
          showPopup();
          return;
        case 2:
          if (state.wordSize <= 40) {
            state.wordSize++;
            localStorage.setItem("wordSize", state.wordSize);
          }
          return;
        case 3:
          if (state.wordSize >= 14) {
            state.wordSize--;
            localStorage.setItem("wordSize", state.wordSize);
          }
          return;
        case 4:
          state.bgColorFlag = !state.bgColorFlag;
          if (state.bgColorFlag) {
            localStorage.setItem("bgColorFlag", false);
          } else {
            localStorage.setItem("bgColorFlag", true);
          }
          state.menuVisible = false;
          return;
      }
    };

    const getChapterData = async () => {
      try {
        state.showData = true;
        let res = await getChapter({ bookPath: state.chapterPath });
        if (state.isFromHome) {
          getDetail(bookItem.chapterUrl, bookItem.nowChapterNum - 1);
        } else {
          getDetail(res.data.chapterList[0].chapterUrl);
        }
        state.chapterData = [...res.data.chapterList];
        state.chapterTitle = res.data.chapterTitle;
        state.showData = false;
      } catch (error) {
        state.showData = false;
        state.dataGetError = true;
        console.log(error);
      }
    };
    //  获取具体章节内容
    const getDetail = async (chapterUrl, chapterNum = 0) => {
      const { data } = await getChapterDetail({ chapterUrl });
      state.chapterDetail = { ...data };
      state.showChapterPop = false;
      state.nowChapterNum = chapterNum + 1;
      state.prevPageLoading = false;
      state.nextPageLoading = false;
      let content = document.querySelectorAll(".content");
      content[0].scrollTop = 0;

      const bookData = state.chapterData.find(
        (v) => v.chapterUrl === chapterUrl
      );

      storageBook({ ...bookData, nowChapterNum: state.nowChapterNum });
    };

    // 显示目录
    const showPopup = () => {
      state.showChapterPop = true;

      // let content = document.querySelectorAll('.position-chapter')
      // console.log(content[0].offsetHeight)
      // content[0].scrollTop = 700
    };

    const refresh = () => {
      window.location.reload();
    };

    const prev = () => {
      state.prevPageLoading = true;

      getDetail(
        state.chapterData[state.nowChapterNum - 2].chapterUrl,
        state.nowChapterNum - 2
      );
    };

    const next = () => {
      state.nextPageLoading = true;
      getDetail(
        state.chapterData[state.nowChapterNum].chapterUrl,
        state.nowChapterNum
      );
    };

    const storageBook = (bookData) => {
      const datas = { ...bookData, ...bookItem };
      if (localStorage.getItem("bookList")) {
        // 已经有书了
        let data = JSON.parse(localStorage.getItem("bookList"));
        let index = data.findIndex((v) => v.bookPath === bookItem.bookPath);
        if (index != -1) {
          // 找到相同书籍了
          data[index] = { ...bookItem, ...bookData };
          data.unshift(data[index]);
          data.splice(index + 1, 1);
        } else {
          // 没有相同书籍
          data.unshift(datas);
        }
        localStorage.setItem("bookList", JSON.stringify(data));
      } else {
        // 第一次进来
        let data = [datas];
        localStorage.setItem("bookList", JSON.stringify(data));
      }
    };
    return {
      ...toRefs(state),
      refresh,
      showPopup,
      getDetail,
      next,
      prev,
      menuClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.white {
  background: #fff;
}
.yellow {
  background: -webkit-linear-gradient(left, #edddb4, #f4e8c6);
}
.chapter-container {
  height: 100%;
  // overflow: auto;

  ::v-deep .skeleton {
    margin: 15px;
  }

  .nutyellow {
    ::v-deep .nut-fixednav__btn {
      background: #f1e7c7;
      > .text {
        color: #8c7f5e;
      }
    }
  }

  .nutwhite {
    ::v-deep .nut-fixednav__btn {
      background: #fff;
      > .text {
        color: #000;
      }
    }
  }

  h2 {
    font-weight: 700;
    padding: 15px 0;
    text-align: center;
    font-size: 22px;
  }

  .box {
    padding: 15px;
    font-size: 16px;
    background: -webkit-linear-gradient(left, #edddb4, #f4e8c6);
  }

  .error-nodata {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    // margin-top: 200px;
    > p {
      margin-top: 10px;
      color: rgb(199, 88, 149);
    }

    i {
      font-size: 20px;
    }
  }

  .detail {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      color: #8c7f5e;
      height: 25px;
      width: 100%;
      // padding: 10px;
      // padding-right: 100px;

      .menu {
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-right: 20px;
        border: 1px solid #8c7f5e;
        border-radius: 8px;
        padding: 2px 4px;
        font-size: 14px;
      }
    }

    .content {
      // flex: 1;
      margin-top: 20px;
      // font-size: 20px;
      line-height: 1.3;
      overflow: auto;
      height: 580px;
      ::v-deep .readinline {
        display: none;
      }
    }

    .detail-content {
      min-height: 100%;
    }

    .page-wrap {
      display: flex;
      justify-content: center;

      ::v-deep .nut-button--info {
        &.btn-white {
          background: #fff;
          border: 1px solid #1677ff;
          color: #1677ff;
        }
        &.btn-yellow {
          background: rgb(229, 158, 21);
        }
      }

      .prev {
        margin-right: 20px;
      }
    }
  }
}
</style>

<style lang="scss">
.nut-popup {
  background: -webkit-linear-gradient(left, #edddb4, #f4e8c6);
  h2 {
    font-size: 18px;
    margin: 15px 0 10px 10px;
  }

  .boxs {
    background: -webkit-linear-gradient(left, #edddb4, #f4e8c6);

    h2 {
      font-weight: 700;
    }
  }

  .chapter-list-box {
    overflow: auto;
    height: 560px;
    font-size: 16px;
  }

  .new-chapter {
    display: flex;
    justify-content: space-between;
    margin: 20px 10px;
  }

  .new-title {
    font-size: 13px;
    color: #9e8f6c;
  }

  .box {
    margin-left: 10px;
    margin-bottom: 25px;
  }

  .check-chapter {
    color: rgb(229, 158, 21);
  }

  .position-chapter {
    padding: 5px 0;
  }
}
</style>
